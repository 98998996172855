import { Button, Input, Stack, Typography } from '@mui/joy'
import * as React from 'react'
import { useAPI } from '../controllers/ContextProvider'

export default function ChangePassword() {
    const [pwd, setPWd] = React.useState({ pwd: '', cpwd: '', npwd: '' })
    let { post } = useAPI()
    const onChange = (e, v) => {
        let p = pwd
        p[e] = v
        setPWd(p)
    }
    const onSubmit = () => {
        post('auth/changePassword',).then(r => {
            if (r.data.success) {
                alert("Password changed successfully!")
            }
        })
    }
    return (
        <div>
            <form onSubmit={(e) => { e.preventDefault(); onSubmit() }}>
                <Stack direction="column" justifyContent={"center"} alignItems={"center"} sx={{ height: window.innerHeight - 100 }}>
                    <Typography>
                        Change password
                    </Typography>
                    <Input type='password' required placeholder='Current password' onChange={(e) => { onChange('pwd', e.target.value) }} />
                    <Input type='password' required placeholder='New password' onChange={(e) => { onChange('cpwd', e.target.value) }} />
                    <Input type='password' required placeholder='Confirm new password' onChange={(e) => { onChange('npwd', e.target.value) }} />
                    <Button type='submit'>Change password</Button>
                </Stack>
            </form>
        </div>
    )
}