import * as React from 'react'
import { useRoutes } from 'react-router'
import ManagementWindow from './windows/management'
import UsersPanel from './panels/forms/users'
import Logout from './pages/logout'
import AuthFormAcsolar from './pages/authform_acsolar'
import { BusinessList, BusinessView } from './panels/business/businessList'
import { AdsList, AdView } from './panels/business/ads'
import WelcomePage from './pages/welcome'
import ChangePassword from './pages/changepassword'
export default function AppRoute() {
    return useRoutes([
        {
            element: <AuthFormAcsolar />,
            path: '/'
        },
        {
            element: <AuthFormAcsolar del={true} />,
            path: '/deleteAccount'
        },
        {
            element: <WelcomePage/>,
            path: '/welcome/:tag'
        },
        {
            element: <ManagementWindow />,
            path: '/forms',
            children: [
                { path: 'users', element: <UsersPanel /> },
                { path: 'changepwd', element: <ChangePassword/>},
                { path: 'logout', element: <Logout /> },
                {path: 'businesses',element:<BusinessList/>},
                {path: 'business', element: <BusinessView/>},
                {path: 'ads', element: <AdsList/>},
                {path: 'ad', element: <AdView/>}
            ]
        }
    ])
}
