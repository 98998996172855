import { Button, Card, Typography } from "@mui/joy";
import { Grid } from "@mui/material";
import * as React from "react";
import { useAPI } from "../../controllers/ContextProvider";
import KTable from "../../components/table";
import { useLocation, useNavigate, useParams } from "react-router";
import { KUtility } from "../../components/utility";
export function BusinessList() {
  let { get } = useAPI();
  let nav = useNavigate();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    get("bservices/listBusinesses").then((r) => {
      setData(r.data);
    });
  }, []);
  return (
    <div>
      <Card>
        <KTable
          columns={[
            { field: "id", headerName: "Id" },
            { field: "name", headerName: "Name" },
            { field: "description", headerName: "Details" },
            { field: "address", headerName: "Address" },
            { field: "city", headerName: "City" },
            { field: "country", headerName: "Country" },
            { field: "experience", headerName: "Experience" },
          ]}
          rows={data}
          onRowClick={(e) => {
            nav("/forms/business", {
              state: KUtility.getById(data, "id", e.row.id),
            });
          }}
        />
      </Card>
    </div>
  );
}
export function BusinessView() {
    const data = useLocation().state;
  let { getFile, post } = useAPI();
  const updateStatus = (status)=>{
    post('bservices/approveBusiness',{status: status,bid: data.id})
  }
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <h2>Business name</h2>
        <p>{data.name}</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Business Address</h2>
        <p>{data.address}</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Experience</h2>
        <p>{data.experience}</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>City</h2>
        <p>{data.city}</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Country</h2>
        <p>{data.country}</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Description</h2>
        <p>{data.description    }</p>
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Logo</h2>
        <img src={getFile(data.logo)} style={{ width: 400, height: 400 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Business Licence</h2>
        <img src={getFile(data.license)} style={{ width: 400, height: 400 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <h2>Government Issued ID</h2>
        <img src={getFile(data.govId)} style={{ width: 400, height: 400 }} />
      </Grid>
      <Grid item xs={12} md={12}>
        {/* {data.status === "pending" ? ( */}
          <div>
            <Button onClick={()=>{updateStatus('approved')}} style={{marginRight:10,marginLeft:10}}>Approve Business</Button>
            <Button onClick={()=>{updateStatus('pending')}} color="neutral" style={{marginRight:10,marginLeft:10}}>Add To pending list</Button>
            <Button onClick={()=>{updateStatus('denied')}} color="danger">Revoke Business</Button>
          </div>
        {/* // ) : null} */}
      </Grid>
    </Grid>
  );
}
