import { CardContent, Card, Stack, Typography, TextField, Button } from '@mui/joy'
import * as React from 'react'
import { FloatingLabelInput } from '../components/input'
import { Login } from '@icon-park/react'
import { useAPI } from '../controllers/ContextProvider';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import { Loading } from '../components/loader';
export default function AuthFormAcsolar() {
    const [email, setEmail] = React.useState(null);
    const [pwd, setPwd] = React.useState(null);
    const [error, setError] = React.useState('');
    let { get, post, setToken,app, getFile } = useAPI();

    let nav = useNavigate();
    const onsubmit = () => {
        Loading.showLoader("Authenticating");
        post('auth/login', {
            email: email,
            password: pwd,
            role:'1',
        }).then((r) => {
            console.log(r)
            if (r.data.authorization.token!==undefined) {
                setToken(r.data.authorization.token)
                nav('/forms')
            }
            else {

                
                setError('Wrong username or password');
            }
            Loading.hideLoader();
        }).catch(e => {
            setError('Wrong username or password');
            Loading.hideLoader();

        })
    }
    return (
        <div>
        <Grid container className='animate__animated' style={{backgroundImage:'url("'+ getFile(app.wallpaper)+'")', backgroundSize:"cover",}}>
            <Grid item xs={12} md={8} lg={8}></Grid>
            <Grid item xs={12} md={4} lg={4} style={{boxShadow:"0px 0px 20px #333"}}>
                <Stack style={{height:window.innerHeight,
                    width:"100%",
                    backgroundColor:"rgba(255,255,255,0.99)",
                    justifyContent:"center",
                    alignItems:"center",
                    backdropFilter:"blur(15px)",
                    
                  }}>
                    <div style={{padding:10,width:"80%"}}>
                    <form action="apk.js" onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <center className='animate__animated animate__fadeInDown'>

                        <img src={getFile(app.logo)} style={{ height: 200, width: "auto", borderRadius: 100 }} />
                        <Typography fontWeight={"bold"} variant='h5'>{app.name}</Typography>
                        <div style={{height: 40}}></div>
                        <Typography variant="body1" color='#f00' style={{ color: "#f00" }} fontWeight={"bold"}>{error}</Typography>
                    </center>
                    <div  className='animate__animated animate__fadeInUp'>

                        <FloatingLabelInput required={true} name='email' label={"Email Address"} onChange={(e) => {
                            setEmail(e.target.value)
                        }} type={"email"} />
                        
                        <FloatingLabelInput required={true} name='password' label={"Password"} onChange={(e) => {
                            setPwd(e.target.value)
                        }} type={"password"} />
                        <Stack justifyContent={"space-between"} direction={"row"}alignItems={"center"}>
                            <Typography href='#' onClick={()=>{nav('/register')}}><b>Not yet a member?</b></Typography>
                            <Button type='submit'  style={{float:"right"}} onClick={onsubmit} title='Sign Up' endDecorator={<Login />} size='lg' variant='solid'>Sign In</Button>
                        </Stack>
                    </div>
                        {/* <FloatingLabelInput required={true} label={"device_id"} onChange={(e) => {
                            onchange(e.target.name,e.target.value)
                        }} type={"text"} />
                        <FloatingLabelInput required={true} label={"Email Address"} onChange={(e) => {
                            onchange(e.target.name,e.target.value)
                        }} type={"email"} /> */}
                        </form>
                    </div>
                </Stack>
            </Grid>
        </Grid>
    </div>
    )
}