import * as React from 'react'
import {useAPI} from '../../controllers/ContextProvider';
import {FloatingLabelInput, LocalitySelector} from '../../components/input';
import {Button, Checkbox, FormLabel, Radio, RadioGroup, Textarea} from '@mui/joy';
import {Grid, Stack} from '@mui/material';
import {Dropzone, FileMosaic} from "@files-ui/react";
import Select from 'react-select'
import {hideDialog, showDialog} from '../../components/dialog';
import {Loading} from '../../components/loader';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

export function FormBuilderView({preform}) {
    const [form, setForm] = React.useState({fields: [], topic: {name: '', description: '', code: ''}});
    let {formId, get, setShowForm, formRequestData} = useAPI()

    React.useEffect(() => {
        if (preform === undefined) {
            if (formId > 0) {
                get(`forms/getForm/${formId}`).then((r) => {
                    setForm(r.data);
                    // setAdditionalFormData({});
                });
            } else if (formRequestData.formId > 0) {
                get(`forms/getForm/${formRequestData.formId}/byObject/${formRequestData.addonValue}/${formRequestData.addonType}`).then((r) => {
                    setForm(r.data);

                })
            }
        } else {
            setForm(preform)
        }
    }, [formId, formRequestData])


    return (
        <div>

                <KForm form={form}/>

        </div>
    )
}

export function KForm({form, additionalData = null}) {
    const [formData, setFormData] = React.useState({});
    const [files, setFiles] = React.useState({});
    const [, forceUpdate] = React.useState();
    let {upload, additionalFormData, setShowForm, onFormSubmit,loaderStyle} = useAPI()
    const [additional, setAdditional] = React.useState(additionalData !== null ? additionalData : additionalFormData);
    const onChange = (e, v) => {
        let f = formData
        f[e] = v
        setFormData(f)
        forceUpdate({})
    }
    const onFileChange = (e,v)=>{
        console.log(v)
        let f = files;
        f[e] = v;
        setFiles(f);
        forceUpdate({})
    }
    const submit = () => {
        const forsm = document.forms.fosrm;
        let fd = new FormData(forsm)
        Loading.showLoader("Submitting",loaderStyle);
        Object.keys(additional).map((field) => {
            fd.append(field, additional[field])
        })
        fd.append('filenames', JSON.stringify(form.filenames))
        fd.append('topic', form.topic.id);
        fd.append('textData', JSON.stringify(formData))
        fd.append('code', form.topic.code)
        console.log(files)
        // console.log()
        form.filenames.forEach((e)=>{
            files[e].forEach(file=>{

           fd.append(e,file.file,e)
            })
        });
        upload('forms/add/responses', fd).then((r) => {
            Loading.hideLoader(loaderStyle);
            showDialog()
            setShowForm(false);

            setTimeout(() => {
                hideDialog()
            }, 3000);
            console.log(onFormSubmit)
            
            
        });
    }
    return (
        <form id={"fosrm"} encType={"multipart/form-data"} onSubmit={(e) => {
            e.preventDefault()
            submit()
        }}>
            {
                form.fields.map((prop, key) => (
                    <div>
                        {prop.map((field, key) => (
                            <div item xs={12}>
                                {field.parent ? formData['g' + field.parentElement] !== field.parentValue ? null :
                                    <GetInput multiple={field.multiple} onChange={onChange} onFileChange={onFileChange} input={field}
                                              type={field.type.name}/> :
                                    <GetInput multiple={field.multiple} onChange={onChange} onFileChange={onFileChange} input={field}
                                              type={field.type.name}/>}
                            </div>
                        ))}
                    </div>
                ))
            }
            <div style={{marginTop: 20}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Button type='submit'>Continue</Button>
                </Stack>
            </div>
        </form>
    )
}

function TheInput({type, input, onChange, multiple = "0", onFileChange}) {
    if (multiple === "1") {
        input.multiple = multiple === "1";
        input["mn"] = '[]'
    } else {
        input["mn"] = ''
    }
    console.log(type)
    input["iname"] = 'g' + input.id + input.mn
    switch (type) {
        case 'textarea':
            return <KTextArea e={input} onChange={onChange}/>;
        case 'text':
        // return <TextInput e={input} onChange={onChange}/>;
        case 'date':
            return <TextInput e={input} onChange={onChange}/>;
        case 'number':
            return <TextInput e={input} onChange={onChange}/>;
        case 'tabularinput':
            return <TabularInput e={input} onChange={onChange}/>;
        case 'select':
            return <DSelect e={input} onChange={onChange}/>;
        case 'file':
            return <FileUpload e={input} onChange={onFileChange}/>;
        case 'Pair':
            return <PairInput e={input} onFileChange={onFileChange} onChange={onChange}/>;
        case 'YesNo':
            return <YesNo e={input} onChange={onChange}/>;
        case 'time':
            return <TextInput e={input} onChange={onChange}/>;
        case 'image':
            return <FileUpload onChange={onFileChange} e={input}/>;
        case 'region':
        case 'district':
        case 'ward':
        case 'street':
            return <LocalitySelector e={input} type={type} onChange={onChange} />
        default:
            return null;
    }
}

function formToJson(formId) {
    const form = document.forms.fosrm;
    const formData = new FormData(form);
    const jsonObject = {};

    for (const [key, value] of formData.entries()) {
        console.log([key, value])
        // If the key already exists in the JSON object, convert its value to an array
        if (jsonObject.hasOwnProperty(key)) {
            // If it's not already an array, make it an array
            if (!Array.isArray(jsonObject[key])) {
                jsonObject[key] = [jsonObject[key]];
            }
            // Push the new value to the array
            jsonObject[key].push(value);
        } else {
            // If the key does not exist, simply set it
            jsonObject[key] = value;
        }
    }

    return JSON.stringify(jsonObject);
}

export function FormBuilder() {
    let {showForm} = useAPI();
    return (
        showForm ? <FormBuilderView/> : null
    )
}

function GetInput({type, input, onChange, onFileChange, multiple = "0"}) {
    const [p, setP] = React.useState([0]);
    // console.log(multiple)
    const [, forceUpdate] = React.useState();
    const addField = () => {
        let po = p
        po.push(0)
        setP(po)
        forceUpdate({});
        console.log(type.toLowerCase())

    }
    React.useEffect(()=>{
        console.log(type.toLowerCase().includes("select"))
    },[])
    return (
        <Grid container>
            {p.map((pp) => <Grid item xs={12}>
                <TheInput onFileChange={onFileChange} type={type} input={input} onChange={onChange} multiple={multiple}/>
            </Grid>)}
            <Grid item xs={3}>
                {!type.toLowerCase().includes("select") && multiple === "1" ?
                    <Button style={{marginBottom: 5}} onClick={addField}>Add field</Button> : null}
            </Grid>
        </Grid>
    )
}

function YesNo({e, onChange}) {
    return (
        <div>
            <FormLabel>{e.name}</FormLabel>
            <RadioGroup name={e.iname} onChange={(v) => {
                onChange('g' + e.id + e.mn, v.target.value)
            }}>
                <Radio value={"Yes"} label={"Yes"}/>
                <Radio value={"No"} label={"No"}/>
            </RadioGroup>
        </div>
    )
}

function KRadio({e, onChange}) {
    return (
        <div>
            <FormLabel>{e.name}</FormLabel>
            <RadioGroup name={e.iname} onChange={(v) => {
                onChange('g' + e.id + e.mn, v.target.value)
            }}>
                {e.options.map((prop, key) => (
                    <Radio value={prop.value} label={prop.name}/>
                ))}
            </RadioGroup>
        </div>
    )
}

function TextInput({e, onChange}) {
    return (<FloatingLabelInput label={e.name} type={e.type.name} onChange={(ee) => {
        onChange(e.iname, ee.target.value)
    }} min={0} required={e.required === "1"} name={'g' + e.id + e.mn}/>)
}

function KCheckbox({e, onChange}) {
    React.useEffect(() => {
        onChange(e.iname, "0");
    }, []);
    return (
        <Checkbox name={e.iname} label={e.name} value={"1"} onChange={(ev) => {
            onChange('g' + e.id + e.mn, ev.target.checked ? "1" : "0")
        }}/>
    )
}

export function DSelect({e, onChange, live = false}) {
    const [, forceupdate] = React.useState();

    let {get} = useAPI();
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 5,
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            // height: '30px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
    };
    const optionfy = () => {
        let g = []
        e.options.forEach((prop) => {
            g.push({label: prop.name, value: prop.value});
        })
        return g;
    }
    const addField = () => {
        let p = nOpt
        p.push(1)
        setNOpts(p)
        forceupdate({})
    }
    const getLive = () => {
        setTimeout(()=>{
            if (live) {
                document.getElementById("g" + e.c_rules.parent_element).addEventListener("change",(ev) => {
                    let p = document.getElementById("g" + e.c_rules.parent_element).value;
                    get(`forms/input/liveSelect/${e.c_rules.db}/${e.c_rules.field}/${p}`).then((r) => {
                        setOptions(optionfy(r.data));
                    })
                })
            }
        },3000);
    }
    React.useEffect(() => {
        console.log(e.options)
        getLive();
    }, []);
    const [options, setOptions] = React.useState(e.options);
    const [nOpt, setNOpts] = React.useState([1]);
    return (
        <Grid spacing={1} container>
            {
                nOpt.map((prop, key) => (
                    <Grid item xs={12}>
                        <Select id={"g" + e.id} name={e.iname} styles={customStyles} placeholder={e.name}
                                minMenuHeight={20}
                                options={optionfy(options)}
                                isMulti ={e.multiple === "1" || e.multiple === true}
                                onChange={(eb,v) => {
                                    console.log([eb,v])
                                    if(Array.isArray(eb)){
                                        let p =[]
                                        eb.forEach(t=>{
                                            p.push(t.value)
                                        })
                                        onChange('g' + e.id, p)
                                    }
                                    else {
                                        onChange('g' + e.id + e.mn, eb.value)
                                    }
                                }}/>
                    </Grid>
                ))
            }
        </Grid>
        // <KSelect options={e.options} valueField={"id"} name={e.name} onChange={(ev)=>{alert(ev.target.value);onChange('g'+e.id,ev.target.value)}}/>
    )
}

function KTextArea({e, onChange}) {
    return (<Textarea name={e.iname} placeholder={e.name} minRows={4} onChange={eb => {
        onChange('g' + e.id + e.mn, eb.target.value)
    }} required={e.required}/>)
}

function TabularInput() {

}

function FileUpload({e, onChange}) {
    const [files, setFiles] = React.useState([]);
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };
    React.useEffect(() => { 
        console.log(files);
    }, [files]);
    const updateFiles = (incoming) => {
        setFiles(incoming);
        onChange('g'+e.id, incoming)
        // console.log(incoming)
    }
    return (
        <div>
            <FormLabel>{e.name}</FormLabel>
            <Dropzone
                accept={e.c_rules.accept}
                onChange={updateFiles}
                value={files}
                //accept="image/*"
            >
                {files.map((file) => (
                    <FileMosaic key={file.id} {...file} onDelete={removeFile} info/>
                ))}
            </Dropzone>
        </div>
    )
}

function PairInput({e, onChange, onFileChange}) {
    const [pairs, setPairs] = React.useState([1]);
    const [, forceUpdate] = React.useState()
    const [data, setData] = React.useState([]);
    const addField = () => {
        let p = pairs;
        p.push(1);
        setPairs(p);
        forceUpdate({})
    }
    const onPairChange = (i, ie, v, prop) => {
        let p = data;
        if(p.length-1 >= i){
        }
        else{
            p.push({})
        }
        p[i][prop] = v;
        setData(p);
        console.log([p.length,i])
        onChange("g"+e.id, p);
    }
    return (
        <div>
            <p>{e.name}</p>
            {
                pairs.map((prop, key) => (
                    <div>
                        {e.pair_size === 2 ? <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <GetInput  onFileChange={onFileChange} type={e.pair1.type.name} multiple={"0"} input={e.pair1} onChange={(ev, v) => {
                                    onPairChange(key, ev, v,e.pair1.name)
                                }}/>
                            </Grid>
                            <Grid item xs={6}>
                                <GetInput onFileChange={onFileChange} type={e.pair2.type.name} multiple={"0"} input={e.pair2} onChange={(ev, v) => {
                                    onPairChange(key, ev, v,e.pair2.name)
                                }}/>
                            </Grid>
                        </Grid> : <Grid spacing={1} container>
                            <Grid item xs={4}>
                                <GetInput onFileChange={onFileChange} type={e.pair1.type.name} multiple={"0"} input={e.pair1} onChange={(ev, v) => {
                                    onPairChange(key, ev, v,e.pair1.name)
                                }}/>
                            </Grid>
                            <Grid item xs={4}>
                                <GetInput onFileChange={onFileChange} type={e.pair2.type.name} multiple={"0"} input={e.pair2} onChange={(ev, v) => {
                                    onPairChange(key, ev, v,e.pair2.name)
                                }}/>
                            </Grid>
                            <Grid item xs={4}>
                                <GetInput onFileChange={onFileChange} type={e.pair3.type.name} multiple={"0"} input={e.pair3} onChange={(ev, v) => {
                                    onPairChange(key, ev, v,e.pair3.name)
                                }}/>
                            </Grid>
                        </Grid>}
                    </div>
                ))
            }
            <Button style={{marginBottom: 5}} type="button" variant={"outlined"} onClick={addField}>Add Field</Button>
        </div>
    )
}

